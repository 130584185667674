@font-face {
  font-family: 'GmarketSansLight';
  src: url('./GmarketSans/GmarketSansTTFLight.ttf');
}

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('./GmarketSans/GmarketSansTTFMedium.ttf');
}

@font-face {
  font-family: 'GmarketSansBold';
  src: url('./GmarketSans/GmarketSansTTFBold.ttf');
}

@font-face {
  font-family: 'PretendardMedium';
  src: url('./pretendard/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'PretendardBlack';
  src: url('./pretendard/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: 'PretendardBold';
  src: url('./pretendard/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'PretendardExtraBold';
  src: url('./pretendard/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'PretendardSemiBold';
  src: url('./pretendard/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'PretendardRegular';
  src: url('./pretendard/Pretendard-Regular.woff') format('woff');
}
