@import '../assets/fonts/font.css';

.input {
  display: flex;
  width: 100%;
  height: 35px;
  font-family: 'PretendardMedium';
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: #989898;
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-bottom: 10px;
}

input::placeholder {
  color: #989898;
  font-family: 'PretendardMedium';
  font-size: 11px;
}
