@import '../assets/fonts/font.css';

.button {
  width: 64px;
  height: 34px;
  font-family: 'PretendardMedium';
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  /* font-weight: 500; */
  color: #ffffff;
  background-color: #000000;
  border: none;
  border-radius: 25px;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  /* transition: all 0.3s ease 0s; */
  cursor: pointer;
  outline: none;
}
